import clsx from 'clsx';
import { ReactNode } from 'react';
import PromotionalBanner from '../trips/TripsPage/PromotionalBanner';
import Footer from './Footer';
import Header from './Header';

export interface LayoutProps {
  children: ReactNode;
  className?: string;
}

export default function DefaultLayout({
  children,
  className = 'py-3',
}: LayoutProps) {
  return (
    <>
      <div
        className="d-flex flex-column"
        style={{
          minHeight: '100vh',
          height: 'auto',
        }}
        id="App"
      >
        <PromotionalBanner />
        <Header />
        <div className={clsx('flex-grow-1', className)}>{children}</div>
        <Footer />
      </div>
    </>
  );
}
