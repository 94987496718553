import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useContext, useState } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Store } from '../../Store';
import AuthenticationModal from '../auth/AuthenticationModal';
import ContactUsLink from '../help/ContactUsLink';
import TrustPilotWidget from '../trustpilot/TrustPilotWidget';

type Destination = {
  label: string;
  link: string;
};

const destinations: Destination[] = [
  { label: 'Albania', link: '/go/albania' },
  { label: 'Croatia', link: '/go/croatia' },
  { label: 'France', link: '/go/france' },
  { label: 'Greece', link: '/go/greece' },
  { label: 'Iceland', link: '/go/iceland' },
  { label: 'Italy', link: '/go/italy' },
  { label: 'Norway', link: '/go/norway' },
  { label: 'Slovakia', link: '/go/slovakia' },
  { label: 'Slovenia', link: '/go/slovenia' },
  { label: 'Spain', link: '/go/spain' },
  { label: 'Sweden', link: '/go/sweden' },
  { label: 'Switzerland', link: '/go/switzerland' },
  { label: 'United Kingdom', link: '/go/united-kingdom' },
].sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

const getSortedDestinations = (): Destination[] => {
  const half = Math.ceil(destinations.length / 2);
  const firstHalf = destinations.slice(0, half);
  const secondHalf = destinations.slice(half);

  const sortedDestinations: Destination[] = [];
  for (let i = 0; i < half; i++) {
    if (firstHalf[i]) sortedDestinations.push(firstHalf[i]);
    if (secondHalf[i]) sortedDestinations.push(secondHalf[i]);
  }

  return sortedDestinations;
};

const europeDestinations = getSortedDestinations();

export default function Header() {
  const { state } = useContext(Store);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { push } = useRouter();

  const isUserLoggedIn = !!state.user.id;
  const isUserHost = state.user.host_roles?.length > 0;
  const isUserAdmin = !!state.user.roles.includes('admin');

  return (
    <>
      <style jsx global>{`
        .europe-dropdown .dropdown-menu {
          width: 350px !important;
        }

        .europe-dropdown .show {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
          .europe-dropdown .dropdown-menu {
            width: auto !important;
          }

          .europe-dropdown .show {
            display: block;
          }

          .dropdown-menu {
            margin-left: 1.25rem;
          }
        }

        .special-dropdown .dropdown-menu::before {
          border-right: unset;
          border-bottom: unset;
          border-left: unset;
          position: absolute;
        }

        .special-dropdown .dropdown-menu::after {
          border-right: unset;
          border-bottom: unset;
          border-left: unset;
          position: absolute;
        }
      `}</style>
      <Navbar
        expand="lg"
        bg="white"
        variant="light"
        className="border-bottom py-2 px-3"
        collapseOnSelect
        style={{ zIndex: 5 }}
      >
        <>
          <Link href="/" prefetch={false} legacyBehavior>
            <div className="d-flex align-items-center" role="button">
              {/*   eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Image
                src="/images/logo/logo.svg"
                className="d-inline-block"
                alt="Skyhook Home"
                width={18}
                height={18}
                quality={100}
              />
              <strong data-cy="brand-name" className="text-dark ps-1">
                Skyhook
              </strong>
            </div>
          </Link>
        </>
        <Nav className="d-flex flex-row d-print-none">
          <Navbar.Toggle />
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="me-auto mx-3">
            {/* Host Pages */}
            {isUserHost && !isUserAdmin && (
              <Fragment key="host">
                <Link href="/hosts/dashboard" passHref legacyBehavior>
                  <Nav.Link data-cy="url-host-dashboard">
                    Host Dashboard
                  </Nav.Link>
                </Link>
              </Fragment>
            )}
            {/* Admin Only Pages */}
            {isUserAdmin && (
              <Fragment key="admin">
                <Link href="/admin" passHref legacyBehavior>
                  <Nav.Link data-cy="url-admin">Admin</Nav.Link>
                </Link>
              </Fragment>
            )}

            <NavDropdown title="Destinations" id="destinations-dropdown">
              {/* Africa */}
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="Africa"
                id="africa"
              >
                <Link href="/go/kenya" legacyBehavior passHref>
                  <NavDropdown.Item>Kenya</NavDropdown.Item>
                </Link>
                <Link href="/go/morocco" legacyBehavior passHref>
                  <NavDropdown.Item>Morocco</NavDropdown.Item>
                </Link>
                <Link href="/go/south-africa" legacyBehavior passHref>
                  <NavDropdown.Item>South Africa</NavDropdown.Item>
                </Link>
                <Link href="/go/tanzania" legacyBehavior passHref>
                  <NavDropdown.Item>Tanzania</NavDropdown.Item>
                </Link>
              </NavDropdown>

              {/* Central America */}
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="Central America"
                id="central-america"
              >
                <Link href="/go/guatemala" legacyBehavior passHref>
                  <NavDropdown.Item>Guatemala</NavDropdown.Item>
                </Link>
              </NavDropdown>

              {/* Europe */}
              <NavDropdown
                className="special-dropdown europe-dropdown"
                drop="end"
                title="Europe"
                id="europe"
              >
                {europeDestinations.map((destination) => (
                  <Link
                    key={destination.label}
                    href={destination.link}
                    passHref
                    legacyBehavior
                  >
                    <NavDropdown.Item // hide on mobile
                      className="d-none d-sm-block"
                    >
                      {destination.label}
                    </NavDropdown.Item>
                  </Link>
                ))}
                {destinations.map((destination) => (
                  <Link
                    key={destination.label}
                    href={destination.link}
                    passHref
                    legacyBehavior
                  >
                    <NavDropdown.Item // hide on desktop
                      className="d-block d-sm-none"
                    >
                      {destination.label}
                    </NavDropdown.Item>
                  </Link>
                ))}
              </NavDropdown>

              {/* Middle East & Asia */}
              <NavDropdown
                className="special-dropdown"
                title="Middle East & Asia"
                id="middle-east-asia"
                drop="end"
                navbar
              >
                <Link href="/go/jordan" legacyBehavior passHref>
                  <NavDropdown.Item>Jordan</NavDropdown.Item>
                </Link>
                <Link href="/go/nepal" legacyBehavior passHref>
                  <NavDropdown.Item>Nepal</NavDropdown.Item>
                </Link>
                <Link href="/go/thailand" legacyBehavior passHref>
                  <NavDropdown.Item>Thailand</NavDropdown.Item>
                </Link>
              </NavDropdown>

              {/* North America */}
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="North America"
                id="north-america"
              >
                <Link href="/go/cuba" legacyBehavior passHref>
                  <NavDropdown.Item>Cuba</NavDropdown.Item>
                </Link>
                <Link href="/go/united-states" legacyBehavior passHref>
                  <NavDropdown.Item>United States</NavDropdown.Item>
                </Link>
              </NavDropdown>

              {/* South America */}
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="South America"
                id="south-america"
              >
                <Link href="/go/argentina" legacyBehavior passHref>
                  <NavDropdown.Item>Argentina</NavDropdown.Item>
                </Link>
                <Link href="/go/chile" legacyBehavior passHref>
                  <NavDropdown.Item>Chile</NavDropdown.Item>
                </Link>
                <Link href="/go/ecuador" legacyBehavior passHref>
                  <NavDropdown.Item>Ecuador</NavDropdown.Item>
                </Link>
                <Link href="/go/peru" legacyBehavior passHref>
                  <NavDropdown.Item>Peru</NavDropdown.Item>
                </Link>
              </NavDropdown>
              <Link href="/trips" legacyBehavior passHref>
                <NavDropdown.Item
                  style={{
                    color: '#00000080',
                  }}
                  className="nav-link"
                >
                  See All Trips
                </NavDropdown.Item>
              </Link>
            </NavDropdown>

            <NavDropdown
              className="special-dropdown"
              title="Experiences"
              id="experiences-dropdown"
            >
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="Activities"
                id="activities"
              >
                <Link href="/go/climbing" legacyBehavior passHref>
                  <NavDropdown.Item>Climbing</NavDropdown.Item>
                </Link>
                <Link href="/go/hiking" legacyBehavior passHref>
                  <NavDropdown.Item>Hiking</NavDropdown.Item>
                </Link>
                <Link href="/go/kayaking-and-canoeing" legacyBehavior passHref>
                  <NavDropdown.Item>Kayaking & Canoeing</NavDropdown.Item>
                </Link>
                <Link href="/go/mixed-activity" legacyBehavior passHref>
                  <NavDropdown.Item>Mixed Activity</NavDropdown.Item>
                </Link>
                <Link href="/go/mountain-summits" legacyBehavior passHref>
                  <NavDropdown.Item>Mountain Summits</NavDropdown.Item>
                </Link>
                <Link href="/go/surfing-and-coastal" legacyBehavior passHref>
                  <NavDropdown.Item>Surfing & Coastal</NavDropdown.Item>
                </Link>
                <Link href="/go/winter-activities" legacyBehavior passHref>
                  <NavDropdown.Item>Winter Activities</NavDropdown.Item>
                </Link>
              </NavDropdown>
              <NavDropdown
                className="special-dropdown"
                drop="end"
                title="Bucket List"
                id="bucket-list"
              >
                <Link href="/trips/aconcagua" legacyBehavior passHref>
                  <NavDropdown.Item>Aconcagua</NavDropdown.Item>
                </Link>
                <Link href="/go/climb-kilimanjaro" legacyBehavior passHref>
                  <NavDropdown.Item>Climb Kilimanjaro</NavDropdown.Item>
                </Link>
                <Link href="/go/everest-base-camp" legacyBehavior passHref>
                  <NavDropdown.Item>Everest Base Camp</NavDropdown.Item>
                </Link>
                <Link href="/go/machu-picchu" legacyBehavior passHref>
                  <NavDropdown.Item>Machu Picchu</NavDropdown.Item>
                </Link>
                <Link href="/go/patagonia" legacyBehavior passHref>
                  <NavDropdown.Item>Patagonia</NavDropdown.Item>
                </Link>
                <Link href="/go/3-peaks-challenge-uk" legacyBehavior passHref>
                  <NavDropdown.Item>Three Peaks Challenge</NavDropdown.Item>
                </Link>{' '}
                <Link
                  href="/trips/tour-du-mont-blanc-highlights"
                  legacyBehavior
                  passHref
                >
                  <NavDropdown.Item>Tour du Mont Blanc</NavDropdown.Item>
                </Link>
              </NavDropdown>

              <Link href="/trips" legacyBehavior passHref>
                <NavDropdown.Item
                  style={{
                    color: '#00000080',
                  }}
                  className="nav-link"
                >
                  See All Trips
                </NavDropdown.Item>
              </Link>
            </NavDropdown>

            <Link href="/trips" legacyBehavior passHref>
              <Nav.Link>Search Trips</Nav.Link>
            </Link>

            <Link href="/deals" passHref legacyBehavior>
              <Nav.Link>On Sale</Nav.Link>
            </Link>

            <NavDropdown title="About" id="about-dropdown">
              <Link href="/about" legacyBehavior passHref>
                <NavDropdown.Item className="fw-bold text-black-50">
                  About Us
                </NavDropdown.Item>
              </Link>

              <Link href="/reviews" legacyBehavior passHref>
                <NavDropdown.Item className="fw-bold text-black-50">
                  Our Reviews
                </NavDropdown.Item>
              </Link>

              <Link href="/promise" legacyBehavior passHref>
                <NavDropdown.Item className="fw-bold text-black-50">
                  Our Promise
                </NavDropdown.Item>
              </Link>

              <Link href="/club" legacyBehavior passHref>
                <NavDropdown.Item className="fw-bold text-black-50">
                  Skyhook Club
                </NavDropdown.Item>
              </Link>

              <Link href="/blog" legacyBehavior passHref>
                <NavDropdown.Item className="fw-bold text-black-50">
                  Blog
                </NavDropdown.Item>
              </Link>
            </NavDropdown>

            <ContactUsLink
              helpComponent={({ onClick }) => (
                <Nav.Link onClick={onClick} data-cy="url-contacts">
                  Contact
                </Nav.Link>
              )}
            />
          </Nav>
          <Nav className="mx-3 me-auto me-lg-3 my-lg-0">
            {/* Logged in user Pages */}
            {isUserLoggedIn && (
              <Link href={`/me/${state.user.id}`} passHref legacyBehavior>
                <Nav.Link>My Dashboard</Nav.Link>
              </Link>
            )}

            {!state.isAuthenticated && (
              <Nav.Link onClick={() => setShowAuthModal(true)}>
                Login / Register
              </Nav.Link>
            )}

            <div className="nav-link">
              <TrustPilotWidget
                templateId="5419b732fbfb950b10de65e5"
                height="24px"
                className="d-none d-lg-block"
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {showAuthModal && (
        <AuthenticationModal
          showModal={showAuthModal}
          onSuccess={async (signup) => {
            if (signup === 'signup') {
              // If the user just signed up, redirect them to the edit profile page
              push(`/me/edit`);
            }
            setShowAuthModal(false);
          }}
          onHide={() => setShowAuthModal(false)}
        />
      )}
    </>
  );
}
