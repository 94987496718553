import clsx from 'clsx';
import React from 'react';

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, isMicro: boolean) => void;
    };
  }
}

type TrustPilotWidgetProps = {
  className?: string;
  height?: string;
  width?: string;
  templateId: string;
};

export default function TrustPilotWidget({
  className = '',
  height = '150px',
  width = '100%',
  templateId,
}: TrustPilotWidgetProps) {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={clsx('trustpilot-widget', className)}
        data-locale="en-GB"
        data-template-id={templateId}
        data-businessunit-id="58ad31840000ff00059d23b9"
        data-style-height={height}
        data-style-width={width}
        data-style-color="#343a40"
      >
        <a
          href="https://uk.trustpilot.com/review/skyhookadventure.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
}
