import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import { AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import localForage from 'localforage';
import { useContext, useEffect, useMemo } from 'react';
import { Store } from '../../Store';
import appsyncAuthLink from './links/appsyncAuthLink';
import appsyncLink from './links/appsyncLink';
import cleanTypeNameMutationLink from './links/cleanTypeNameMutationLink';
import offlineErrorLink from './links/offlineErrorLink';
import OfflineMutationLink, {
  useUpdateConnectionStatus,
} from './links/offlineMutationLink';

/**
 * Cache instance
 *
 * You can write to this with SSR generated query responses.
 */
export const cache = new InMemoryCache({
  typePolicies: {
    // cache without id as this is a singleton, we don't expect it to have
    // multiple items
    GeoLocation: {
      keyFields: false,
    },
    // cache with period and type as the key, instead of id
    RevenueReport: {
      keyFields: ['type', 'period'],
    },
    Query: {
      fields: {
        // Add any cache fields here
        // revenueReport: {
        //   // input: { type, period}
        //   keyArgs: [['input', ['type', 'period']]],
        //   merge(
        //     existing: AdminFetchRevenueReportQuery['revenueReport'],
        //     incoming: AdminFetchRevenueReportQuery['revenueReport'],
        //   ) {
        //     return {
        //       items: [...(existing?.items || []), ...(incoming.items || [])],
        //       nextToken: incoming.nextToken,
        //     };
        //   },
        // },
      },
    },
  },
});

const offlineMutationLink = new OfflineMutationLink();

/**
 * Clear the cache on sign out
 */
Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    cache.reset();
  }
});

/**
 * SSR client
 *
 * Used for server-side queries (and also internally here).
 */
export function ssrClient(): ApolloClient<NormalizedCacheObject> {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: Auth.currentCredentials,
  };

  return new ApolloClient({
    name: 'website-ssr-anonymous',
    // Cache must be provided but never used (due to fetch policy below)
    cache: new InMemoryCache({
      typePolicies: {
        // cache without id as this is a singleton, we don't expect it to have
        // multiple items
        GeoLocation: {
          keyFields: [],
        },
      },
    }),
    link: from([
      cleanTypeNameMutationLink,
      appsyncAuthLink(auth),
      appsyncLink(auth),
    ]),
    ssrMode: true,
    assumeImmutableResults: true,
    defaultOptions: {
      query: {
        // Always get the latest data
        fetchPolicy: 'network-only',
      },
    },
  });
}

/**
 * Anonymous client
 *
 * __Generally not used directly.__
 */
function anonymousClient(): ApolloClient<NormalizedCacheObject> {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: Auth.currentCredentials,
  };

  return new ApolloClient({
    name: 'website-anonymous',
    cache,
    link: from([
      cleanTypeNameMutationLink,
      offlineErrorLink,
      appsyncAuthLink(auth),
      appsyncLink(auth),
    ]),
    assumeImmutableResults: true,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        // Logged out users typically don't care about stale data as much, but 5 minutes should make sure e.g.
        // availability is updated regularly enough without issues.
        pollInterval: 5 * 60 * 1000,
      },
    },
  });
}

/**
 * Authenticated client
 *
 * __Generally not used directly.__
 *
 * @param setDefaults Set the default options (if using internally for the provider component below)
 */
export function authenticatedClient(): ApolloClient<NormalizedCacheObject> {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return idToken.getJwtToken();
    },
  };

  return new ApolloClient({
    name: 'website-authenticated',
    cache,
    link: from([
      cleanTypeNameMutationLink,
      offlineMutationLink,
      offlineErrorLink,
      appsyncAuthLink(auth),
      appsyncLink(auth),
    ]),
    assumeImmutableResults: true,
    // For signed in users we enable a persistent cache, which means data can persist for a very long time. It's
    // therefore very important to maintain freshness.
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        pollInterval: 1 * 60 * 1000, // Refresh data every 1 minute
      },
    },
  });
}

/**
 * Persist the cache asynchronously from storage
 */
async function persistCacheAsync() {
  const setupLocalForage = () => {
    return new Promise<void>((resolve) => {
      localForage.ready().then(resolve);
    });
  };

  await setupLocalForage();

  await persistCache({
    cache,
    storage: new LocalForageWrapper(localForage),
  });
}

/**
 * Apollo client provider (for client-side rendering)
 *
 * Used to setup the client for every page on client-side rendering, so you can just use `useQuery`/`useMutation` and it
 * will handle the underlying complexity.
 *
 * Note we have also avoided setting up getDataFromTree (Apollo's built-in SSR from
 * https://www.apollographql.com/docs/react/performance/server-side-rendering/) as the performance is quite poor.
 */
export function AppsyncApolloProvider({ children }: { children: any }) {
  // Get the user state
  const { state } = useContext(Store);

  // Choose the client depending on logged in/out state
  const client = useMemo<ApolloClient<NormalizedCacheObject>>(() => {
    if (state.isAuthenticated === false) return anonymousClient();

    return authenticatedClient();
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (state.isAuthenticated) {
      // Persist the cache asynchronously
      // Note this may cause a content flash if loading the app whilst offline, but that's better than a content flash
      // whilst loading the app online (which happens much more regularly), or a loading indicator.
      const setup = async () => {
        await persistCacheAsync();
        client.reFetchObservableQueries();
      };
      setup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuthenticated]);

  // Enable/disable the offline link depending on network connectivity
  useUpdateConnectionStatus(
    client,
    offlineMutationLink,
    !state.isAuthenticated,
  );

  // Return the page wrapped with an Apollo Provider
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

/**
 * Hydrate the cache from server side rendered data
 */
export function useHydrateApolloCache({ data, query, variables }) {
  return useMemo(() => {
    if (data) {
      cache.writeQuery({
        data,
        // If the user already has the item in their cache, we won't over-write it (as they may have just edited it)
        overwrite: false,
        query,
        variables,
      });
    }
  }, [data, query, variables]);
}
